import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import Downloadpage from './Download'
import reportWebVitals from './reportWebVitals';
import './i18n';
import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createHashRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "/downloadpage",
    element: <Downloadpage/>,
  }
]);

root.render(
  <RouterProvider router={router}></RouterProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
