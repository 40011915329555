const UserPolicy = () => {
  return (
    <div className="user-policy-page">
      <div className="container">
        <h1>《用户协议》</h1>
        <br/>
        小爱团队（以下简称“我们”）依据本协议为用户（以下简称“你”）提供小爱聊天服务。本协议对你和我们均具有法律约束力。
        <br/>
        <br/>
        <p>
          <b>一、本服务的功能</b>
          你可以使用本服务分享生活、聊天、通话、视频。
        </p>
        <p>
          <b>二、责任范围及限制</b>
          你使用本服务得到的结果仅供参考，实际情况以官方为准。
        </p>
        <p>
          <b>三、隐私保护</b>
          我们重视对你隐私的保护，你的个人隐私信息将根据《隐私政策》受到保护与规范，详情请参阅《隐私政策》。
        </p>
        <p>
          <b>四、其他条款</b>
          4.1 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
          <br/>
          4.2 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
        </p>
      </div>
    </div>
  )
}

export default UserPolicy