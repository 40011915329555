import React, { useState, useEffect, useRef, useCallback } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Privacy from './privacy'
import UserPolicy from './userPolicy'

// 刷新重回頁首
window.onbeforeunload = function () {
  window.scrollTo(0, 0);
}

function App() {

  const { t, i18n } = useTranslation(['Common', 'HomePage', 'PrivacyPolicy']);
  const [activeTab, setActiveTab] = useState('home')
  const [isCrypto, setIsCrypto] = useState(false)
  const [isChat, setIsChat] = useState(false)
  const [isVideo, setIsVedio] = useState(false)
  const [isGroup, setIsGroup] = useState(false)
  const [isDownload, setIsDownload] = useState(false)
  const navigate = useNavigate();

  const titleRef = useRef()
  const cryptoRef = useRef()
  const chatRef = useRef()
  const videoRef = useRef()
  const groupRef = useRef()

  const handelScroll = useCallback(() => {
    if (window.scrollY > videoRef.current.scrollHeight/5 + cryptoRef.current.scrollHeight/4 + titleRef.current.scrollHeight/4 + chatRef.current.offsetTop/4 + groupRef.current.offsetTop/2) {
      setIsGroup(true)
    } else if (window.scrollY > cryptoRef.current.scrollHeight/5 + titleRef.current.scrollHeight/5 + chatRef.current.offsetTop/4 + videoRef.current.offsetTop/2) {
      setIsVedio(true)
    } else if (window.scrollY > cryptoRef.current.scrollHeight/5 + titleRef.current.scrollHeight/5 + chatRef.current.offsetTop / 2) {
      setIsChat(true)
    } else if (window.scrollY > cryptoRef.current.offsetTop/2 - titleRef.current.scrollHeight/10) {
      setIsCrypto(true)
    }
  }, [])

  useEffect(() => {
    if (activeTab !== 'home') {
      setIsChat(false)
      setIsCrypto(false)
      setIsVedio(false)
      setIsGroup(false)
      window.removeEventListener('scroll', handelScroll)
    }
    if (cryptoRef.current && cryptoRef.current && chatRef.current && videoRef.current && groupRef.current && activeTab === 'home') {
      window.scrollTo(0, 0);
      window.addEventListener('scroll', handelScroll)
    }
  }, [activeTab, handelScroll])

  const closeDownLoad = (ev) => {
    ev.stopPropagation()
    if (ev.target.className === 'download' || ev.target.className === 'download-icon') return
    setIsDownload(false)
  }

  const downloadHandel = (ev) => {
    if (ev.target.className !== 'download') return
    if (window.innerWidth < 576) {
      // 手機邏輯
      navigate('/downloadPage')
    } else {
      setIsDownload(true)
    }
  }

  return (
    <div className="App" onClick={(ev) => {closeDownLoad(ev)}}>
      <div className="nav">
        <div className="logo">
          <img className="logo-icon" src={process.env.PUBLIC_URL + "/logo.png"} alt="logo"/>
          {
            i18n.language.includes('zh') ? 
            <img className="logo-text zh" src={process.env.PUBLIC_URL + "/logoText_zh.png"} alt="logo"/> :
            <img className="logo-text en" src={process.env.PUBLIC_URL + "/logoText_en.png"} alt="logo"/>
          }
        </div>
        <div className="option">
          <div className={`home ${activeTab === 'home' ? 'active' : null}`}
            onClick={() => setActiveTab('home')}>
              {t('Common:Navbar.link_home')}
          </div>
          <div className={`privacy ${activeTab === 'privacy' ? 'active' : null}`}
            onClick={() => setActiveTab('privacy')}>
              {t('Common:Navbar.link_privacy_policy')}
          </div>
          <div className={`user ${activeTab === 'user' ? 'active' : null}`}
            onClick={() => setActiveTab('user')}>
              {t('Common:Navbar.link_user_policy')}
          </div>
          <div className="download" 
            onClick={(ev) => downloadHandel(ev)}>
              {t('Common:Navbar.button_download')} <span className="download-icon"></span>
              <div className={`QRCode-container ${isDownload ? 'active' : null}`}>
                <div className="QRCode-inner">
                  <div className="QRCode">
                    { 
                      window.location.href.includes('xatest') ? 
                      <div className="code"></div> :
                      <div className="prd-code"></div> 
                    }
                  </div>
                  <div className="QRCode-word">{t('Common:Navbar.text_scan_qr_code')}</div>
                </div>
              </div>
          </div>
        </div>
      </div>
      {activeTab === 'home' ?  
      <div className="all-content">
        <div className='title' ref={titleRef}>
          <div className="word-H5">
            <Trans i18nKey="HomePage:Content.MainDesc.title" components={{ insert: <span /> }} />
            <h5>{t('HomePage:Content.MainDesc.sub_text')}</h5>
            <div className="title-icon"></div>
          </div>
          <div className="title-pic"></div>
          <div className="container">
            <div className="word-PC">
              <Trans i18nKey="HomePage:Content.MainDesc.title" components={{ insert: <br /> }} />
              <h5>{t('HomePage:Content.MainDesc.sub_text')}</h5>
              <div className="title-icon"></div>
            </div>
            <div className="content">
              {Array.from({length: 10}, (_, index) => index+1).map(index => <div key={`idx-${index}`} className={`chat-${index}`}></div>)}
            </div>
          </div>
        </div>
        <div className='crypto' ref={cryptoRef}>
          <div className="container">
            <div className={`word ${isCrypto ? 'active' : null}`}>
              <Trans i18nKey="HomePage:Content.SubDescSecurity.title" />
              <h5>{t('HomePage:Content.SubDescSecurity.sub_text')}</h5>
            </div>
            <div className={`phone ${isCrypto ? 'active' : null}`}>
              <div className={`crypto-icon ${isCrypto ? 'active' : null}`}></div>
            </div>
          </div>
        </div>
        <div className='chat' ref={chatRef}>
          <div className="container">
            <div className={`phone ${isChat ? 'active' : null}`}>
              <div className="star-1"></div>
              <div className="star-2"></div>
              <div className="star-3"></div>
              <div className="star-4"></div>
              <div className="icon-1"></div>
              <div className="icon-2"></div>
            </div>
            <div className={`word ${isChat ? 'active' : null}`}>
              {t('HomePage:Content.SubDescRichChatTypes.title')}
              <h5>{t('HomePage:Content.SubDescRichChatTypes.sub_text')}</h5>
              <div className="icon-box">
                <div className="icon-1"><h5>{t('HomePage:Content.SubDescRichChatTypes.type_file')}</h5></div>
                <div className="icon-2"><h5>{t('HomePage:Content.SubDescRichChatTypes.type_audio')}</h5></div>
                <div className="icon-3"><h5>{t('HomePage:Content.SubDescRichChatTypes.type_video')}</h5></div>
                <div className="icon-4"><h5>{t('HomePage:Content.SubDescRichChatTypes.type_image')}</h5></div>
              </div>
            </div>
          </div>
        </div>
        <div className='video-chat' ref={videoRef}>
          <div className="container">
            <div className={`word ${isVideo ? 'active' : null}`}>
            <Trans i18nKey="HomePage:Content.SubDescConnection.title"></Trans>
            <h5>{t('HomePage:Content.SubDescConnection.sub_text')}</h5>
            </div>
            <div className={`phone ${isVideo ? 'active' : null}`}>
              <div className={`cloud1 ${isVideo ? 'active' : null}`}></div>
              <div className={`cloud2 ${isVideo ? 'active' : null}`}></div>
              <div className={`icon ${isVideo ? 'active' : null}`}></div>
            </div>
          </div>
        </div>
        <div className='group-chat' ref={groupRef}>
          <div className="container">
            <div className={`phone ${isGroup ? 'active' : null}`}>
              <div className={`chat1 ${isGroup ? 'active' : null}`}></div>
              <div className={`chat2 ${isGroup ? 'active' : null}`}></div>
              <div className={`chat3 ${isGroup ? 'active' : null}`}></div>
              <div className={`icon ${isGroup ? 'active' : null}`}></div>
            </div>
            <div className={`word ${isGroup ? 'active' : null}`}>
              {t('HomePage:Content.SubDescGroup.title')}
              <h5>{t('HomePage:Content.SubDescGroup.sub_text')}</h5>
            </div>
          </div>
        </div>
      </div> : null}

      {activeTab === 'privacy' ? <Privacy/> : null}

      {activeTab === 'user' ? <UserPolicy/> : null}
      
    </div>
  );
}

export default App;
