import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import HttpBackend from 'i18next-http-backend'

i18n
   // 偵測使用者的語言
   .use(detector)
   // 使用 i18next-http-backend
   .use(HttpBackend)
   // 將 i18next 傳入 react-i18next 裡面
   .use(initReactI18next)  
   // 實例化 initReactI18next
   .init({
      backend: {
         // i18next-http-backend 讀取語言檔的路徑
         loadPath: "/locales/{{lng}}/{{ns}}.json",
      },
      // 當目前的語言檔找不到對應的字詞時，會用 fallbackLng (en) 作為預設語言
      fallbackLng: "zh-CN",
      // 匹配讀取方式 currentOnly => en-US, zh-TW, zh-CN
      load: 'currentOnly',
      // 預設讀取的命名空間
      ns: ["Common"],
      // 命名空間分隔號
      nsSeparator: ":",
      interpolation: {
         // 是否要讓字詞 escaped 來防止 xss 攻擊，這裡因為 React.js 已經做了，就設成 false即可
         escapeValue: false,
      },
      react: {
         transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'span']
      }
   });

export default i18n;