import React, { useState } from "react"
import { useTranslation } from 'react-i18next';

const Downloadpage = () => {
  const { i18n } = useTranslation();
  // const [downloadLink, setDownloadLink] = useState('')
  // const [isShowModal, setIsShowModal] = useState(false)
  
  // const detectSafari = () => {
  //   let isSafari = false
  //   const userAgentString = navigator.userAgent; 
  //   const safariAgent = /^((?!chrome|android).)*safari/i.test(userAgentString);
  //   if (safariAgent) isSafari = true
  //   return isSafari
  // }

  // const copyToClipperboard = () => {
  //   const value = downloadLink;
  //   const el = document.createElement('textarea');
  //   el.value = value;
  //   document.body.appendChild(el);
  //   el.select();
  //   document.execCommand('copy');
  //   document.body.removeChild(el);
  //   alert('复制成功!')
  // }

  const downloadIos = () => {
    fetch('/api/forehead/system/version/download/info', {
      method: 'POST',
      headers: {
        "appType": 10,
      },
    }).then(res => res.json()).then(res => {
      const { data } = res
      const { ios } = data
      // 超級簽1 應用商店0
      if (ios.updateType === 0 || ios.updateType === 1) {
        // if (!detectSafari()) {
        //   setDownloadLink(ios.url)
        //   setIsShowModal(true)
        //   return
        // }
        // 暫時拿掉錯誤判斷
        // fetch(ios.url).then(res => {
        //   const { status } = res
        //   if (status === 200) window.location.href = ios.url
        // }).catch(err => {
        //   window.alert('发生错误，暂时无法下载')
        // })
        window.location.href = ios.url
        // 企業簽2
      } else if (ios.updateType === 2) {
        fetch(ios.url).then(res => {
          const { status } = res
          if (status === 200) {
            const link = document.createElement("a");
            link.href = `itms-services://?action=download-manifest&url=${ios.url}`;
            link.download = ios.versionName;
            link.click();
          } else {
            alert('暂时无法下载')
            return
          }
        }).catch(err => {
          alert('暂时无法下载')
          return
        })
      }
    }).catch(err => {
      alert(err)
    })
  }

  const downloadAndroid = () => {
    fetch('/api/forehead/system/version/download/info', {
      method: 'POST',
      headers: {
        "appType": 10,
      },
    }).then(res => res.json()).then(res => {
      const { data } = res
      const { android } = data
      fetch(android.url).then(res => {
        const { status } = res
        if (status === 200) {
          const link = document.createElement("a");
          link.href = android.url;
          link.download = android.versionName;
          link.click();
        } else {
          alert('暂时无法下载')
          return
        }
      })
    }).catch(err => {
      alert(err)
    })
  }

  return (
    <div className="download-page">
      <div className="download-logo">
        <img className="download-logo_icon" src={process.env.PUBLIC_URL + "/downloadLogo.png"} alt="logo"/>
        {
          i18n.language.includes('zh') ? 
          <img className="download-logo_text zh" src={process.env.PUBLIC_URL + "/downloadLogoText_zh.png"} alt="logo"/> :
          <img className="download-logo_text en" src={process.env.PUBLIC_URL + "/downloadLogoText_en.png"} alt="logo"/>
        }
      </div>
      <div className="btn">
        <div onClick={downloadIos} className="download-iOS">苹果下载</div>
        <div onClick={downloadAndroid} className="download-android">安卓下载</div>
      </div>
      {/* {isShowModal && <div className="modal-overlay">
        <div className="modal-body">
          <div className="modal-close" onClick={() => setIsShowModal(false)}>X</div>
          <div className="modal-header"></div>
          <div className="modal-content">
            <div className="title">
              仅支持Safari浏览器进行下载
            </div>
            <div className="content">
              请复制连结后前往Safari贴上
            </div>
          </div>
          <div className="modal-link">
            <div className="link">{downloadLink}</div>
            <div className="copy" onClick={copyToClipperboard}>copy</div>
          </div>
        </div>
      </div>} */}
    </div>
  )
}

export default Downloadpage